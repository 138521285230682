import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { PackageListDimensionsInputs } from './PackageListDimensionsInputs';
import { SymbolCol } from './packageListCommon';
import {
  DimensionalWeightResult,
  WeightInput,
} from './PackageListPackageEditingMode';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';

function QuoteWeightDimsRow({ index, inputProps, saveChanges }) {
  const media = useResponsiveQueries();
  const symbolWidth = media.xs ? 25 : 32;
  return (
    <>
      <Row className="quote-package-weight-result">
        <div style={{ display: 'flex' }}>
          <DimensionalWeightResult
            index={index}
            separator="="
            labelId="book.newShipment.package.dims.dimensionalWeight"
          />
        </div>
        <Button
          type="primary"
          size="small"
          onClick={saveChanges}
          data-subject="packages"
          data-action="save"
          className="quotes-calculate-btn"
        >
          <FAIcon icon="calculator" className="icon-15" />{' '}
          <FormattedMessage id="book.newQuote.calculateShort" />
        </Button>
      </Row>
      <Row align="top" className="quote-packages-inputs">
        <Col flex="3">
          <WeightInput index={index} {...inputProps} />
        </Col>
        <SymbolCol className="DimensionSeparator">
          <FAIcon icon="plus-square" className="icon-22" />
        </SymbolCol>
        <PackageListDimensionsInputs
          index={index}
          inputColProps={{ flex: '3' }}
          symbolColProps={{ style: { width: symbolWidth } }}
          inputProps={inputProps}
        />
      </Row>
    </>
  );
}

export function QuotePackagesEditingMode({ index, saveChanges }) {
  return (
    <div className="NewShipmentPackageListForm">
      <div className="new-quote-package-header">
        <p>
          <FormattedMessage
            id="book.newQuote.packageItemHeaderWithNumber"
            values={{ index: index + 1 }}
          />
        </p>
      </div>
      <div>
        <div className="spaces-vert-md">
          <QuoteWeightDimsRow saveChanges={saveChanges} />
        </div>
      </div>
    </div>
  );
}
