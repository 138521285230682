import { Col } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { WeightFormat } from '../../../components/domainSpecific/measuringUnitsElements';
import { useFormContext } from '../../../components/forms/forms';
import { ConfigurableCard } from '../../../components/layout/cardElements';
import { FlexCol } from '../../../components/layout/layoutElements';
import {
  extractActualWeight,
  extractBilledWeight,
  getPackageCount,
  getPackageTotal,
} from '../shipmentCommon';

function SummaryInformation({ labelId, value, badgeSize }) {
  return (
    <Col>
      <FlexCol align="center">
        <div className="NewShipmentPackagesSummary-Label">
          <FormattedMessage id={labelId} />
        </div>
        <div
          className={classNames(
            'NewShipmentPackagesSummary-Badge',
            `size-${badgeSize}`
          )}
        >
          {value}
        </div>
      </FlexCol>
    </Col>
  );
}

export function PackageListSummary() {
  const { values } = useFormContext();

  const { tbd, packages } = values;
  const pkgs = (packages || []).filter(val => !!val);

  return (
    <>
      <SummaryInformation
        labelId="book.newShipment.quantityWeightSummary.count"
        value={
          tbd ? <FormattedMessage id="labels.tbd" /> : getPackageCount(pkgs)
        }
        badgeSize="sm"
      />
      <SummaryInformation
        labelId="book.newShipment.quantityWeightSummary.actualWeight"
        value={
          tbd ? (
            <FormattedMessage id="labels.tbd" />
          ) : (
            <WeightFormat value={getPackageTotal(pkgs, extractActualWeight)} />
          )
        }
        badgeSize="lg"
      />
      <SummaryInformation
        labelId="book.newShipment.quantityWeightSummary.billedWeight"
        value={
          tbd ? (
            <FormattedMessage id="labels.tbd" />
          ) : (
            <WeightFormat value={getPackageTotal(pkgs, extractBilledWeight)} />
          )
        }
        badgeSize="lg"
      />
    </>
  );
}

export default function PackageListSummaryCard() {
  return (
    <ConfigurableCard
      titleId="book.newShipment.quantityWeightSummary"
      bodyClassName="NewShipmentPackagesSummary"
      bodyProps={{ justify: 'space-between' }}
    >
      <PackageListSummary />
    </ConfigurableCard>
  );
}
