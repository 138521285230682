import { gql } from '@apollo/client';

export const CREATE_QUOTE_MUTATION = gql`
  mutation CreateQuoteMutation($input: QuoteInput!) {
    createQuote(input: $input) {
      expiryDate
      amounts {
        type
        value
        desc
      }
      quotedBy
      totalCharges
      currency
      readyDate
      quoteNumber
      account
    }
  }
`;
