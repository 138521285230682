const routes = {
  root: '/',
  login: '/login',
  signup: '/signup',
  passwordReset: '/password-reset',
  monitor: '/monitor',
  quote: '/quote',
  shipmentDetail: '/monitor/shipment/:jobNumber',
  sharingShipmentDetail: '/share/shipment/:token',
  shipmentDetailSuccess: '/monitor/shipment/:jobNumber/success',
  releaseShipment: '/monitor/shipment/:jobNumber/release',
  editShipment: '/monitor/shipment/:jobNumber/release/edit',
  reverseShipment: '/monitor/shipment/:jobNumber/reverse',
  linkDeviceToJob: '/link-device-to-job',
  book: {
    newShipment: '/book/new-shipment',
    frequentShipments: '/book/frequent-shipments',
    savedTemplates: '/book/saved-templates',
    addressBooks: '/book/address-books',
    shipmentHistory: '/book/shipment-history',
  },
  quoting: {
    newQuote: '/quote/newQuote',
    result: '/quote/result',
  },
  tools: {
    addressBookImport: '/tools/address-book-import',
    timeoutTest: '/tools/timeout-test',
  },
  admin: {
    prefix: '/admin',
    userManagement: '/admin/user-management',
    userDetail: '/admin/user-management/user/:userId',
    userDetailSteps: {
      root: '/',
      documentGroup: '/document-group',
      expandedTracking: '/expanded-tracking',
    },
    addUser: '/admin/user-management/add',
    addUserSteps: {
      root: '/',
      linkAccounts: '/link-accounts',
      confirmProfile: '/confirm-profile',
      documentGroup: '/document-group',
      expandedTracking: '/expanded-tracking',
    },
    accountManagement: '/admin/account-management',
    accountDetail: {
      root: '/admin/account-management/account/:accountNumber',
      documents: '/admin/account-management/account/:accountNumber/documents',
      addressBookSharing:
        '/admin/account-management/account/:accountNumber/address-book-sharing',
      serviceTypes:
        '/admin/account-management/account/:accountNumber/service-types',
      commodityTypes:
        '/admin/account-management/account/:accountNumber/commodity-types',
      gpsDevices:
        '/admin/account-management/account/:accountNumber/gps-devices',
      temperatureDevices:
        '/admin/account-management/account/:accountNumber/temperature-devices',
      packageTypes:
        '/admin/account-management/account/:accountNumber/package-types',
      packageTemperatures:
        '/admin/account-management/account/:accountNumber/package-temperatures',
      userList: '/admin/account-management/account/:accountNumber/users',
      addUser: '/admin/account-management/account/:accountNumber/add-user',
      userDetail:
        '/admin/account-management/account/:accountNumber/user/:userId',
    },
    businessType: '/admin/business-profile-type',
    systemSettings: '/admin/system-settings',
  },
};

export default routes;
