import { Col, Row } from 'antd';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { PackageListCardTitle } from './packageListCommon';
import { formatShipmentActualWeight } from '../../../containers/shipmentDetail/shipmentFormats';
import { LengthFormat } from '../../../components/domainSpecific/measuringUnitsElements';
import Whitespace from '../../../components/Whitespace';
import {
  BilledWeightValue,
  PreviewLabelWithValue,
} from './PackageListPackagePreviewMode';
import IconButton from '../../../components/buttons/IconButton';

function DimensionalWeight({ value }) {
  return (
    <span className="NewShipmentPackage-BilledWeight">
      <span className="text-uppercase">
        <FormattedMessage id="book.newShipment.packages.dimensionalWeight" />
        <Whitespace />
        =<Whitespace />
        <span className="NewShipmentPackage-BilledWeightValue">
          <BilledWeightValue value={value} />
        </span>
      </span>
    </span>
  );
}

function CardTitleEditable({ value, startEditing, remove }) {
  return (
    <div className="NewQuotePackage-ControlButtonsRow">
      <div style={{ display: ' flex' }}>
        <DimensionalWeight value={value} />
      </div>
      <div className="NewQuotePackage-ControlButtons">
        <IconButton
          className="icon-16"
          icon="edit"
          onClick={startEditing}
          titleId="buttons.edit"
        />
        <IconButton
          className="icon-16"
          icon="trash-alt"
          onClick={remove}
          titleId="buttons.delete"
        />
      </div>
    </div>
  );
}

export function QuotePackagesPreviewMode({
  index,
  value,
  startEditing,
  remove,
}) {
  return (
    <div>
      <Row
        align="middle"
        justify="space-between"
        className="new-quote-package-header"
      >
        <Col>
          <FormattedMessage
            id="book.newQuote.packageItemHeaderWithNumber"
            values={{ index: index + 1 }}
          />
        </Col>
      </Row>

      <div className="NewShipmentPackage-PreviewBody">
        <PackageListCardTitle
          text={
            <CardTitleEditable
              value={value}
              startEditing={startEditing}
              remove={remove}
            />
          }
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <PreviewLabelWithValue
              labelId="book.newShipment.label.actualWeight"
              text={formatShipmentActualWeight(value)}
            />
          </div>
          <div>
            <PreviewLabelWithValue
              labelId="book.newShipment.label.length"
              text={<LengthFormat value={get(value, 'length')} />}
            />
          </div>
          <div>
            <PreviewLabelWithValue
              labelId="book.newShipment.label.width"
              text={<LengthFormat value={get(value, 'width')} />}
            />
          </div>
          <div>
            <PreviewLabelWithValue
              labelId="book.newShipment.label.height"
              text={<LengthFormat value={get(value, 'height')} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
