import { gql } from '@apollo/client';

import {
  formFieldFragment,
  makeLocationFieldsFragment,
} from './commonFragments';

export const CREATE_SHIPMENT_MUTATION = gql`
  mutation CreateShipmentMutation($input: ShipmentInput!) {
    createShipment(input: $input) {
      jobNumber
    }
  }
`;

export const EDIT_SHIPMENT_MUTATION = gql`
  mutation EditShipmentMutation($jobNumber: String!, $input: ShipmentInput!) {
    editShipment(jobNumber: $jobNumber, input: $input) {
      jobNumber
    }
  }
`;

export const DELETE_SHIPMENT_MUTATION = gql`
  mutation DeleteShipmentMutation($jobNumber: String!) {
    deleteShipment(jobNumber: $jobNumber)
  }
`;

export const PREPARE_SHIPMENT_QUERY = gql`
  query PrepareShipmentMutation(
    $jobNumber: String
    $input: ShipmentInput!
    $generateQuote: Boolean!
  ) {
    prepareShipment(
      jobNumber: $jobNumber
      input: $input
      generateQuote: $generateQuote
    ) {
      quote
      quoteNumber
      originLocation {
        lat
        lng
      }
      destinationLocation {
        lat
        lng
      }
    }
  }
`;

const deviceAddonFragment = `{
  device ${formFieldFragment}
  serialNumber ${formFieldFragment}
  source ${formFieldFragment}
}`;

const locationFieldsFragment = makeLocationFieldsFragment();
export const SHIPMENT_FORM_SCHEMA_QUERY = gql`
  query ShipmentFormSchemaQuery($accountNumber: String!) {
    newShipmentFormSchema(accountNumber: $accountNumber) {
      pickupDateTime ${formFieldFragment}
      deliveryDateTime ${formFieldFragment}
      origin ${locationFieldsFragment}
      destination ${locationFieldsFragment}
      serviceInformation {
        serviceType ${formFieldFragment}
        commodity ${formFieldFragment}
        costCenter ${formFieldFragment}
        bolNumber ${formFieldFragment}
        dangerousGoods ${formFieldFragment}
        exceptedQuantity ${formFieldFragment}
        unNumber ${formFieldFragment}
        additionalInsurance ${formFieldFragment}
        declaredValue ${formFieldFragment}
        shipperSpecificInfo {
          name
          type
          label
          field ${formFieldFragment}
        }
        eoriNumber ${formFieldFragment}
        hsInfo {
          code ${formFieldFragment}
          description ${formFieldFragment}
        }
      }
      referenceInformation {
        currency ${formFieldFragment}
      }
      packages {
        packageSource ${formFieldFragment}
        packagingType ${formFieldFragment}
        commodityTemperature ${formFieldFragment}
        gpsDevices ${deviceAddonFragment}
        temperatureDevices ${deviceAddonFragment}
        actualWeight ${formFieldFragment}
        length ${formFieldFragment}
        width ${formFieldFragment}
        height ${formFieldFragment}
        packageQualificationTime ${formFieldFragment}
        packoutDateTime ${formFieldFragment}
        coolantAddon {
          type ${formFieldFragment}
          source ${formFieldFragment}
          amount ${formFieldFragment}
          replenishTime ${formFieldFragment}
        }
        pieces {
          description ${formFieldFragment}
          partNumber ${formFieldFragment}
          quantity ${formFieldFragment}
        }
      }
    }
  }
`;

export const SHIPMENT_FORM_GET_OPTIONS_QUERY = gql`
  query ShipmentFormGetOptionsQuery($input: FieldOptionsInput!) {
    newShipmentFieldOptions(input: $input) {
      values {
        code
        text
      }
    }
  }
`;

export const VALIDATE_SHIPMENT_FORM_FIELD_QUERY = gql`
  query ValidateShipmentFormFieldQuery($input: AsyncFieldValidationInput!) {
    newShipmentAsyncFieldValidation(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const SHIPMENT_FORM_AUTOFILL_QUERY = gql`
  query ShipmentFormAutofillQuery($input: AsyncFieldAutofillInput!) {
    newShipmentFieldAutofill(input: $input) {
      value
    }
  }
`;

export const LINK_JOB_TO_DEVICE_MUTATION = gql`
  mutation LinkJobToDeviceMutation($input: LinkJobToDeviceInput!) {
    linkJobToDevice(input: $input)
  }
`;
