import { Col, Row } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { WeightUnit } from '../../../app/enums/measuringUnits';
import { formatCodeValue } from '../../../common/utils/formatUtils';
import Whitespace from '../../../components/Whitespace';
import IconButton from '../../../components/buttons/IconButton';
import {
  LengthFormat,
  NumericDurationFormat,
  WeightFormat,
} from '../../../components/domainSpecific/measuringUnitsElements';
import {
  DashedTwoLinesSeparator,
  TwoLinesSeparator,
} from '../../../components/layout/layoutElements';
import { LabelWithValue } from '../../../components/typography';
import { formatShipmentActualWeight } from '../../../containers/shipmentDetail/shipmentFormats';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { DateTimeFormatMedium } from '../../../utils/dateFormatting';
import { extractBilledWeight } from '../shipmentCommon';
import { useShipmentMode } from '../shipmentModes';
import { PackageListPieceListPreviewButton } from './PackageListPieceList';
import {
  DividerCol,
  PackageCard,
  PackageListCardHeader,
  PackageListCardTitle,
  useIsFirstPackageDimensional,
  usePackageListResponsiveQueries,
  useServiceInformationDeps,
} from './packageListCommon';
import { usePackageListFieldVisibilityContext } from './packageListFieldVisibility';

const DateTimeFormat = props => (
  <DateTimeFormatMedium {...props} separator=" | " />
);

function computePreviewRowGutter({ isSm, media }) {
  if (isSm) {
    return cssVariables.spaceMd;
  }
  return media.lg ? cssVariables.spaceNorm2 : cssVariables.spaceXl;
}

function PreviewRow({ title, className, children, noLines, ...rest }) {
  const { isSm, media } = usePackageListResponsiveQueries();
  const gutter = pxToNumber(computePreviewRowGutter({ isSm, media }));

  return (
    <>
      <div className={classNames('NewShipmentPackage-PreviewRow', className)}>
        {title}
        <Row
          className="NewShipmentPackage-PreviewRowCols"
          gutter={gutter}
          {...rest}
        >
          {children}
        </Row>
      </div>
      {!noLines && (
        <TwoLinesSeparator className={classNames('hide-last', className)} />
      )}
    </>
  );
}

export function PreviewLabelWithValue({
  labelId,
  shortLabelId,
  superShortLabelId,
  ...rest
}) {
  const { isSm, media } = usePackageListResponsiveQueries();
  let effectiveLabelId = labelId;
  if (media.xs && superShortLabelId) {
    effectiveLabelId = superShortLabelId;
  } else if ((isSm || media.lg) && shortLabelId) {
    effectiveLabelId = shortLabelId;
  }

  return (
    <LabelWithValue
      labelSize="full"
      labelNowrap
      labelId={effectiveLabelId}
      {...rest}
    />
  );
}

function ResponsiveCol({ className, ...rest }) {
  return <Col className={className} {...rest} />;
}

function TitleWithExtra({ titleId, extra }) {
  return (
    <Row align="top" justify="space-between">
      <Col>
        <PackageListCardTitle textId={titleId} />
      </Col>
      <Col className="line-height-1">{extra}</Col>
    </Row>
  );
}

export function BilledWeightValue({ value }) {
  return value.unknownWeight ? (
    <FormattedMessage id="book.newShipment.label.unknown" />
  ) : (
    <WeightFormat value={extractBilledWeight(value)} />
  );
}
function BilledWeight({ value }) {
  const { isSm, media } = usePackageListResponsiveQueries();
  return (
    <span className="NewShipmentPackage-BilledWeight">
      <span className="text-uppercase">
        <FormattedMessage
          id={
            isSm || media.lg
              ? 'book.newShipment.quantityWeightSummary.billedWeight.short'
              : 'book.newShipment.quantityWeightSummary.billedWeight'
          }
        />
        <Whitespace />
        =<Whitespace />
        <BilledWeightValue value={value} />
      </span>
    </span>
  );
}

function PackageDescriptionRows({ value }) {
  const { isLg } = usePackageListResponsiveQueries();
  const { hasTemperature } = useShipmentMode();
  const { commodity } = useServiceInformationDeps();
  const isDimWeight = useIsFirstPackageDimensional();

  return (
    <>
      <PreviewRow
        title={
          <TitleWithExtra
            titleId="book.newShipment.packages.packageDescription"
            extra={!isDimWeight && <BilledWeight value={value} />}
          />
        }
        noLines={isDimWeight}
      >
        <ResponsiveCol className="size-lg-3 size-sm-3">
          <PreviewLabelWithValue
            labelId="book.newShipment.label.packageSource"
            superShortLabelId="book.newShipment.label.packageSource.superShort"
            text={formatCodeValue(value?.packageSource)}
          />
        </ResponsiveCol>
        <ResponsiveCol className="size-lg-2 size-sm-4">
          <PreviewLabelWithValue
            labelId="book.newShipment.label.packagingType"
            text={formatCodeValue(value?.packagingType)}
          />
        </ResponsiveCol>
        <ResponsiveCol className="size-lg-1 size-sm-3">
          <PreviewLabelWithValue
            labelId="book.newShipment.label.commodity"
            text={commodity}
          />
        </ResponsiveCol>
        {hasTemperature && (
          <ResponsiveCol className="size-lg-4 size-sm-4">
            <PreviewLabelWithValue
              labelId="book.newShipment.label.commodityTemperature"
              text={formatCodeValue(get(value, 'commodityTemperature'))}
            />
          </ResponsiveCol>
        )}
      </PreviewRow>
      {isDimWeight && (
        <>
          <DashedTwoLinesSeparator />
          <PreviewRow
            title={
              isLg && (
                <PackageListCardTitle text={<BilledWeight value={value} />} />
              )
            }
          >
            <ResponsiveCol className="hide-lg-and-bigger size-sm-3">
              <PreviewLabelWithValue
                labelId="book.newShipment.quantityWeightSummary.billedWeight"
                text={<BilledWeightValue value={value} />}
              />
            </ResponsiveCol>
            <ResponsiveCol className="size-lg-3 size-sm-4">
              <PreviewLabelWithValue
                labelId="book.newShipment.label.actualWeight"
                text={formatShipmentActualWeight(value)}
              />
            </ResponsiveCol>
            <ResponsiveCol className="size-lg-2 size-sm-3">
              <PreviewLabelWithValue
                labelId="book.newShipment.label.length"
                text={<LengthFormat value={get(value, 'length')} />}
              />
            </ResponsiveCol>
            <ResponsiveCol className="size-lg-1 size-sm-2">
              <PreviewLabelWithValue
                labelId="book.newShipment.label.width"
                text={<LengthFormat value={get(value, 'width')} />}
              />
            </ResponsiveCol>
            <ResponsiveCol className="size-lg-4 size-sm-1">
              <PreviewLabelWithValue
                labelId="book.newShipment.label.height"
                text={<LengthFormat value={get(value, 'height')} />}
              />
            </ResponsiveCol>
          </PreviewRow>
        </>
      )}
    </>
  );
}

const SensorsContent = ({ value }) => (
  <>
    <PackageListCardTitle textId="book.newShipment.packages.sensors" />
    <div className="spaces-vert-norm">
      <PreviewLabelWithValue
        size="md"
        labelId="book.newShipment.packages.deviceAddons.preview"
        text={
          <ol className="NewShipmentPackage-DeviceAddons">
            {get(value, 'deviceAddons', [])
              .filter(addon => !!addon?.device)
              .map(addon => (
                <li key={addon.device.code}>
                  <div>{addon.device.text}</div>
                </li>
              ))}
          </ol>
        }
      />
    </div>
  </>
);
function TemperatureAndSensorsRow({ value }) {
  const { packoutDateTime } = usePackageListFieldVisibilityContext();
  const { hasTemperature } = useShipmentMode();
  const { isLg, isSm } = usePackageListResponsiveQueries();

  return (
    <>
      {(isLg || hasTemperature) && (
        <PreviewRow>
          {hasTemperature && (
            <>
              <ResponsiveCol className="size-lg-3 size-sm-3">
                <PackageListCardTitle textId="book.newShipment.packages.temperature" />
                <div className="spaces-vert-norm1_5">
                  {packoutDateTime && (
                    <PreviewLabelWithValue
                      labelId="book.newShipment.packages.packoutDateTime"
                      shortLabelId="book.newShipment.packages.packoutDateTime.short"
                      superShortLabelId="book.newShipment.packages.packoutDateTime.superShort"
                      text={
                        <DateTimeFormat value={get(value, 'packoutDateTime')} />
                      }
                    />
                  )}
                  <PreviewLabelWithValue
                    labelId="book.newShipment.label.packageQualificationTime"
                    shortLabelId="book.newShipment.label.packageQualificationTime.short"
                    text={
                      <NumericDurationFormat
                        value={get(value, 'packageQualificationTime')}
                      />
                    }
                  />
                </div>
              </ResponsiveCol>
              <DividerCol smallVerticalSpace />
              <ResponsiveCol className="size-lg-2 size-sm-2">
                <PackageListCardTitle textId="book.newShipment.packages.coolantAddons" />
                <div className="spaces-vert-norm1_5">
                  <PreviewLabelWithValue
                    labelId="book.newShipment.label.coolantSource"
                    superShortLabelId="book.newShipment.label.coolantSource.superShort"
                    text={formatCodeValue(get(value, 'coolantAddon.source'))}
                  />
                  <PreviewLabelWithValue
                    labelId="book.newShipment.label.coolantAmount"
                    superShortLabelId="book.newShipment.label.coolantAmount.superShort"
                    text={
                      <WeightFormat
                        value={get(value, 'coolantAddon.amount')}
                        unit={WeightUnit.KILOGRAM}
                      />
                    }
                  />
                </div>
              </ResponsiveCol>
              <ResponsiveCol className="size-lg-1 size-sm-1">
                <PackageListCardTitle text=" " />
                <div className="spaces-vert-norm1_5">
                  <PreviewLabelWithValue
                    labelId="book.newShipment.label.coolantType"
                    superShortLabelId="book.newShipment.label.coolantType.superShort"
                    text={formatCodeValue(get(value, 'coolantAddon.type'))}
                  />
                  <PreviewLabelWithValue
                    labelId="book.newShipment.label.replenishTime"
                    superShortLabelId="book.newShipment.label.replenish"
                    text={
                      <NumericDurationFormat
                        value={get(value, 'coolantAddon.replenishTime')}
                      />
                    }
                  />
                </div>
              </ResponsiveCol>
              <DividerCol className="hide-md-and-smaller" smallVerticalSpace />
            </>
          )}
          <ResponsiveCol className="hide-md-and-smaller size-lg-4">
            <SensorsContent value={value} />
          </ResponsiveCol>
        </PreviewRow>
      )}
      {isSm && (
        <PreviewRow>
          <Col span={24}>
            <SensorsContent value={value} />
          </Col>
        </PreviewRow>
      )}
    </>
  );
}

export default function PackageListPackagePreviewMode({
  index,
  value,
  startEditing,
  remove,
  pieceListProps,
  disabled,
  viewMode,
}) {
  return (
    <PackageCard
      index={index}
      headerExtra={
        !viewMode && (
          <div className="NewShipmentPackage-ControlButtons">
            <IconButton
              className="icon-18"
              icon="pen-square"
              onClick={startEditing}
              disabled={disabled}
              titleId="buttons.edit"
            />
            <IconButton
              className="icon-16"
              icon="trash-alt"
              onClick={remove}
              disabled={disabled}
              titleId="buttons.delete"
            />
          </div>
        )
      }
    >
      <PackageListCardHeader
        index={index}
        right={
          <PackageListPieceListPreviewButton
            values={value.pieces}
            {...pieceListProps}
            noEdit
          />
        }
      />
      <div className="NewShipmentPackage-PreviewBody">
        <PackageDescriptionRows value={value} />
        <TemperatureAndSensorsRow value={value} />
      </div>
    </PackageCard>
  );
}
