import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { BarcodeOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import { Flex1, TitleWithExtra } from '../components/layout/layoutElements';
import { JobLocationSection } from '../forms/shipments/newShipment/newShipmentElements';
import { LINK_JOB_TO_DEVICE_MUTATION } from '../app/graphql/jobFormQueries';
import ProcessingModal from '../components/dialogs/ProcessingModal';
import { OrderSuccessDialog as SuccessDialog } from '../dialogs/shipments/newShipmentDialogs';
import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';

export default function LinkDeviceToJobPage() {
  const [mutate, { loading }] = useMutation(LINK_JOB_TO_DEVICE_MUTATION);
  const [form, setForm] = useState({
    bol: undefined,
    deviceId: undefined,
  });
  const [errors, setErrors] = useState({
    bol: false,
    deviceId: false,
  });
  const [success, setSuccess] = useState(undefined);
  const [showCamera, setShowCamera] = useState(false);
  const [field, setField] = useState('');

  const getSuffix = scannedField => (
    <BarcodeOutlined
      onClick={() => {
        setField(scannedField);
        setShowCamera(!showCamera);
      }}
      style={{
        fontSize: 24,
        color: '#96a4bd',
      }}
    />
  );

  const reset = () => {
    setForm({
      bol: undefined,
      deviceId: undefined,
    });
    setField('');
    setShowCamera(false);
    setErrors({
      bol: false,
      deviceId: false,
    });
  };

  const onSubmit = async () => {
    const { bol, deviceId } = form || {};

    if (!bol || !deviceId) {
      setErrors({
        bol: !bol,
        deviceId: !deviceId,
      });
      return;
    }

    const input = {
      jobNumber: form?.bol,
      deviceType: 'ROAMBEE',
      deviceModel: 'ROAMBEE',
      deviceId: form?.deviceId,
    };
    const { data } = await mutate({ variables: { input } });
    const { linkJobToDevice } = data || {};
    reset();
    setSuccess(linkJobToDevice === true);
  };

  return (
    <Flex1 className="link-device-to-job-page">
      <TitleWithExtra titleId="linkDeviceToJob.title" />

      <JobLocationSection titleId="linkDeviceToJob.bol">
        <Input
          onChange={event => setForm({ ...form, bol: event.target.value })}
          value={form.bol}
          status={errors?.bol ? 'error' : ''}
          suffix={getSuffix('bol')}
        />
      </JobLocationSection>

      <JobLocationSection titleId="linkDeviceToJob.deviceId">
        <Input
          onChange={event => setForm({ ...form, deviceId: event.target.value })}
          value={form.deviceId}
          status={errors?.deviceId ? 'error' : ''}
          suffix={getSuffix('deviceId')}
        />
      </JobLocationSection>

      {showCamera && (
        <BarcodeScannerComponent
          onUpdate={(_err, result) => {
            if (result) {
              setShowCamera(false);
              console.log(result.getText());
              setForm({
                ...form,
                [field]: result.getText(),
              });
            }
          }}
        />
      )}

      <Button type="primary" onClick={onSubmit}>
        <FormattedMessage id="buttons.save" />
      </Button>

      {loading && (
        <ProcessingModal textId="linkDeviceToJob.linking-job-to-device" />
      )}

      {success && (
        <SuccessDialog
          titleId="linkDeviceToJob.successDialog.title"
          subTitleId="linkDeviceToJob.successDialog.subtitle"
          onOk={() => setSuccess(undefined)}
        />
      )}

      <SimpleConfirmDialog
        visible={success === false}
        onClose={() => setSuccess(undefined)}
        cancelTextId="buttons.close"
        textId="linkDeviceToJob.errorDialog.message"
        centered
        mask
      />
    </Flex1>
  );
}
