import { Fragment, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Spinner } from '../components/data/Spinner';
import AdminGuard from '../components/guards/AdminGuard';
import LoggedInGuard from '../components/guards/LoggedInGuard';
import LoginPage from '../containers/LoginPage';
import MonitorPage from '../containers/MonitorPage';
import NewShipmentPage from '../containers/NewShipmentPage';
import NewQuotePage from '../containers/NewQuotePage';
import QuoteResultPage from '../containers/QuoteResultPage';
import PasswordResetPage from '../containers/PasswordResetPage';
import ReverseShipmentPage from '../containers/ReverseShipmentPage';
import { SharedShipmentDetailPage } from '../containers/ShipmentDetailPage';
import SignupPage from '../containers/SignupPage';
import AdminAccountManagementPage from '../containers/admin/AdminAccountManagementPage';
import AdminBusinessTypePage from '../containers/admin/AdminBusinessTypePage';
import AdminSystemSettingsPage from '../containers/admin/AdminSystemSettingsPage';
import AdminUserManagementPage from '../containers/admin/AdminUserManagementPage';
import LinkDeviceToJobPage from '../containers/LinkDeviceToJobPage';
import { cssVariables } from '../styles/cssVariables';
import { msToNumber } from '../utils/cssUtils';
import { cssTransitionClassNames } from '../utils/reactTransitionUtils';
import { useLoginPageRedirectRoute } from '../utils/redirectUtils';
import { sortRoutes } from '../utils/routeUtils';
import { getLoggedIn } from './redux/auth';
import routes from './routes';

function MonitorRedirect() {
  return <Redirect to={routes.monitor} />;
}

const mappings = sortRoutes([
  {
    path: routes.root,
    component: MonitorRedirect,
    exact: true,
    loginGuard: true,
  },
  { path: routes.monitor, component: MonitorPage, loginGuard: true },
  {
    path: routes.sharingShipmentDetail,
    component: SharedShipmentDetailPage,
  },
  {
    path: routes.book.newShipment,
    component: NewShipmentPage,
    loginGuard: true,
  },
  {
    path: routes.reverseShipment,
    component: ReverseShipmentPage,
    loginGuard: true,
  },
  {
    path: routes.quoting.newQuote,
    component: NewQuotePage,
    loginGuard: true,
  },
  {
    path: routes.quoting.result,
    component: QuoteResultPage,
    loginGuard: true,
  },
  {
    path: routes.tools.addressBookImport,
    lazyComponent: lazy(() => import('../containers/AddressBookImportPage')),
    loginGuard: true,
  },
  {
    path: routes.tools.timeoutTest,
    lazyComponent: lazy(() => import('../containers/TimeoutTestPage')),
    loginGuard: true,
  },
  { path: routes.login, component: LoginPage },
  { path: routes.signup, component: SignupPage },
  { path: routes.passwordReset, component: PasswordResetPage },
  {
    path: routes.admin.userManagement,
    component: AdminUserManagementPage,
    adminGuard: true,
  },
  {
    path: routes.admin.accountManagement,
    component: AdminAccountManagementPage,
    adminGuard: true,
  },
  {
    path: routes.admin.businessType,
    component: AdminBusinessTypePage,
    adminGuard: true,
  },
  {
    path: routes.admin.systemSettings,
    component: AdminSystemSettingsPage,
    adminGuard: true,
  },
  {
    path: routes.linkDeviceToJob,
    component: LinkDeviceToJobPage,
  },
]);

const animations = [
  { path: routes.admin.prefix, exact: false, animationName: 'from-left' },
];

const DefaultRoute = () => {
  const loggedIn = useSelector(getLoggedIn);
  const loginRedirectUrl = useLoginPageRedirectRoute();
  return <Redirect to={loggedIn ? routes.root : loginRedirectUrl} />;
};

const AdminAndLoggedInGuard = ({ children }) => (
  <LoggedInGuard>
    <AdminGuard>{children}</AdminGuard>
  </LoggedInGuard>
);

function renderRouteChildren({
  loginGuard,
  adminGuard,
  component,
  lazyComponent,
}) {
  let OuterGuard = Fragment;
  if (loginGuard) {
    OuterGuard = LoggedInGuard;
  }
  if (adminGuard) {
    OuterGuard = AdminAndLoggedInGuard;
  }

  const InnerGuard = lazyComponent ? Suspense : Fragment;
  const innerGuardProps = lazyComponent ? { fallback: <Spinner /> } : {};
  const Page = lazyComponent || component;

  return routeProps => (
    <OuterGuard>
      <InnerGuard {...innerGuardProps}>
        <Page {...routeProps} />
      </InnerGuard>
    </OuterGuard>
  );
}

function getAnimationDefinition(pathname) {
  return animations.find(({ path, exact }) =>
    exact ? pathname === path : pathname.startsWith(path)
  );
}

function useAnimationProps() {
  const { pathname } = useLocation();

  const anim = getAnimationDefinition(pathname);

  return {
    key: anim?.path || 'no-anim',
    timeout: msToNumber(cssVariables.animationDurationMd),
    classNames: {
      ...(anim
        ? cssTransitionClassNames(anim.animationName, {
            appear: false,
            enter: true,
            exit: true,
          })
        : {}),
    },
  };
}

export default function AppRoutes() {
  const location = useLocation();
  const animationProps = useAnimationProps();
  return (
    <TransitionGroup component={null}>
      <CSSTransition {...animationProps}>
        <Switch location={location}>
          {mappings.map(({ path, exact, ...info }) => (
            <Route key={path} path={path} exact={exact}>
              {renderRouteChildren(info)}
            </Route>
          ))}
          <Route path="*" component={DefaultRoute} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
