import { message } from 'antd';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { startsWith } from 'lodash-es';
import { Prompt, generatePath, useHistory, useRouteMatch } from 'react-router';
import { useMutation } from '@apollo/client';
import moment from 'moment';

import BaseLoggedPage from '../templates/BaseLoggedPage';
import NewQuoteForm from '../forms/shipments/quotes/newQuoteForm';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import useNewQuoteFormState from '../forms/shipments/quotes/useNewQuoteFormState';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { DataStateIndicatorGuard } from '../components/data/dataStateHandlers';
import { FormSanitizationProvider } from '../components/forms/FormSanitizationContext';
import { useAccounts } from '../hooks/data/auth';
import { AccountSelectionDialog } from '../dialogs/shipments/newShipmentDialogs';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { AsyncCallbackButton } from '../components/data/asyncActionElements';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { FlexCol, TitleWithExtra } from '../components/layout/layoutElements';
import ProcessingModal from '../components/dialogs/ProcessingModal';
import { CancelLink } from '../forms/shipments/newShipment/newShipmentElements';
import { PageExitDialogType } from './nav/PageExitDialog';
import routes from '../app/routes';
import { CREATE_QUOTE_MUTATION } from '../app/graphql/quoteQueries';
import { toServerDateTimeOptional } from '../common/utils/dateUtils';

function useQuoteSubmitState({
  mutation,
  mutationOptions,
  prepareQuoteQuery, // = PREPARE_SHIPMENT_QUERY,
}) {
  const [mutate, { loading }] = useMutation(mutation, mutationOptions);
  const { push } = useHistory();

  async function submit(values) {
    // TODO do the mapping from form
    const { account, serviceType, origin, destination } = values || {};
    const { data } = await mutate({
      variables: {
        input: {
          account: account?.number,
          bolNo: '',
          serviceType: serviceType?.code,
          quotedBy: 'MOCK',
          orderContact: {},
          pickupDateTime: toServerDateTimeOptional(moment()),
          thirdPartyBillingAccount: '',
          provideBOLPrint: true,
          provideLabelPrint: true,
          shipper: {
            address: {
              addressLine1: origin?.address?.addressLines?.[0],
              city: origin?.address?.city,
              stateProvince: values['origin,address'].stateProvince,
              postalCode: origin?.address?.postalCode,
              country: 'US',
            },
          },
          consignee: {
            address: {
              addressLine1: destination?.address?.addressLines?.[0],
              city: destination?.address?.city,
              stateProvince: values['destination,address'].stateProvince,
              postalCode: destination?.address?.postalCode,
              country: 'US',
            },
          },
          packageInfo: [],
        },
      },
    });
    push(
      generatePath(routes.quoting.result, {
        quoteNumber: data?.createQuote?.quoteNumber,
      })
    );
  }

  return {
    submit,
    isLoading: loading,
  };
}

function NewQuoteExitPrompt() {
  return (
    <Prompt
      message={({ pathname }) =>
        startsWith(pathname, routes.quote)
          ? true
          : PageExitDialogType.CANCEL_QUOTE
      }
    />
  );
}

const NewQuoteButtons = ({ onSubmit, buttonProps, primaryButtonIcon }) => (
  <AsyncCallbackButton
    type="primary"
    data-subject="shipment"
    data-action="save"
    callback={onSubmit}
    loadingTextId="buttons.validating"
    {...buttonProps}
  >
    <span>
      <FormattedMessage id="book.newShipment.getQuote" />
    </span>
    {primaryButtonIcon || <FAIcon icon="chevron-right" />}
  </AsyncCallbackButton>
);

const NewQuotePageInner = () => {
  const { formatMessage } = useIntl();
  const accountsResult = useAccounts();
  const match = useRouteMatch();

  // TODO: create separate FormState hook for newQuote
  const {
    form,
    refreshValues,
    account,
    setAccount,
    unitSystem,
    // setUnitSystem,
    values,
    extractValues,
  } = useNewQuoteFormState();

  const { submit, isLoading } = useQuoteSubmitState({
    mutation: CREATE_QUOTE_MUTATION,
  });

  useEffect(() => {
    if (account)
      message.warning({
        content: (
          <div className="quotes-notification-content">
            {formatMessage({ id: 'book.newQuote.warning-restriction' })}
            <br />
            {formatMessage({ id: 'book.newQuote.warning-contact' })}
            <a
              href="https://quick.aero/contact-us/"
              target="_blank"
              rel="noreferrer"
            >
              <u>
                {formatMessage({
                  id: 'book.newQuote.warning-customer-service',
                })}
              </u>
            </a>
          </div>
        ),
        className: 'quotes-notification',
        duration: 10,
      });
  }, [account, formatMessage]);

  const onSubmit = async () => {
    try {
      await form.validateFields();
      submit(extractValues());
    } catch (e) {
      console.log(JSON.stringify(e, null, 4));
    }
  };

  return (
    <BaseLoggedPage
      id="NewQuotePage"
      className={classNames('height-extending-error')}
    >
      <NewShipmentFormSchemaProvider account={account}>
        <NewQuoteExitPrompt match={match} />
        <DynamicFormDependenciesProvider values={values}>
          <FlexCol className="Flex1">
            <div style={{ width: '100%' }}>
              <OverrideSettingsContextProvider unitSystem={unitSystem}>
                <FormSanitizationProvider>
                  <DataStateIndicatorGuard
                    queryResult={{
                      ...accountsResult,
                      data: accountsResult.customerAccounts,
                    }}
                  >
                    {customerAccounts => (
                      <>
                        <TitleWithExtra
                          rightColClassName="hide-md-and-smaller"
                          title={
                            <>
                              <FormattedMessage id="book.newQuote.title" />
                              <CancelLink
                                className="hide-md-and-smaller"
                                messageId="book.newQuote.cancelQuote"
                              />
                            </>
                          }
                        >
                          <div className="NewShipmentTopControls">
                            <NewQuoteButtons onSubmit={onSubmit} />
                          </div>
                        </TitleWithExtra>
                        {account && (
                          <NewQuoteForm
                            name="newQuoteForm"
                            form={form}
                            onEditedPackageChange={(changed, allValues) =>
                              requestAnimationFrame(() =>
                                refreshValues({ packages: allValues })
                              )
                            }
                          />
                        )}
                        <AccountSelectionDialog
                          value={account}
                          onChange={setAccount}
                          customerAccounts={customerAccounts}
                        />
                      </>
                    )}
                  </DataStateIndicatorGuard>
                </FormSanitizationProvider>
              </OverrideSettingsContextProvider>
            </div>
          </FlexCol>
          {isLoading && (
            <ProcessingModal textId="book.newQuote.optimizing-routing" />
          )}
        </DynamicFormDependenciesProvider>
      </NewShipmentFormSchemaProvider>
    </BaseLoggedPage>
  );
};

const NewQuotePage = NewQuotePageInner;
export default NewQuotePage;
