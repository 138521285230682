import React from 'react';
import classNames from 'classnames';

import BaseLoggedPage from '../templates/BaseLoggedPage';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import useNewQuoteFormState from '../forms/shipments/quotes/useNewQuoteFormState';

export default function QuoteResultPage() {
  const { account, values } = useNewQuoteFormState();

  return (
    <BaseLoggedPage
      id="QuoteResultPage"
      className={classNames('height-extending-error')}
    >
      <NewShipmentFormSchemaProvider account={account}>
        <DynamicFormDependenciesProvider values={values}>
          {/* Replace this with quote result content */}
          <div>Quote Result Page</div>
        </DynamicFormDependenciesProvider>
      </NewShipmentFormSchemaProvider>
    </BaseLoggedPage>
  );
}
