import { Button, Col, Row } from 'antd';
import classNames from 'classnames';
import { isObject } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import routes from '../../../app/routes';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { AsyncCallbackButton } from '../../../components/data/asyncActionElements';
import { FormItemsOverrideContextProvider } from '../../../components/forms/FormItem';
import { FormItemInputText } from '../../../components/forms/basicFormElements';
import { useDynamicOriginalValueDependency } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { NoLabelForm } from '../../../components/forms/forms';
import { ConfigurableCard } from '../../../components/layout/cardElements';
import {
  ThreeLines,
  TwoLines,
} from '../../../components/layout/layoutElements';
import {
  isFirstStep as computeIsFirstStep,
  isLastStep as computeIsLastStep,
} from '../../../components/nav/StepsWithContent';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { InlineLink } from '../../../components/typography';
import AddressLookup from '../../../dialogs/shipments/AddressLookup';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import {
  AddressCountryFormItem,
  AddressFormItems,
} from '../../../widgets/addresses';
import { getPackageCount } from '../shipmentCommon';
import { mergeFormNames } from '../../../components/forms/formHelpers';

/**
 * This is to prevent TAB key to be able to go to inactive slide and break design
 */
function DisableFormItemsInInactiveSlide({ slideActive, children }) {
  return (
    <FormItemsOverrideContextProvider disabled={!slideActive}>
      {children}
    </FormItemsOverrideContextProvider>
  );
}

export function NewShipmentForm({
  name,
  className,
  form,
  formRef,
  onValuesChange,
  slideActive,
  children,
  initialValues,
}) {
  return (
    <DisableFormItemsInInactiveSlide slideActive={slideActive}>
      <NoLabelForm
        form={form}
        formRef={formRef}
        onValuesChange={onValuesChange}
        name={name}
        className={className}
        initialValues={initialValues}
      >
        {children}
      </NoLabelForm>
    </DisableFormItemsInInactiveSlide>
  );
}

export function NewShipmentBottomControls({
  submitStep,
  stepsProps,
  isQuoteEnabled,
  cancelComponent: Cancel = CancelShipmentLink,
}) {
  const isPackageEdited = !!useDynamicOriginalValueDependency('packages');
  if (isPackageEdited) {
    return null;
  }
  return (
    <div className="hide-lg-and-bigger spaces-vert-sm">
      <ThreeLines />
      <Row align="middle">
        <Col>
          <Cancel />
        </Col>
        <Col className="Flex1">
          <div className="NewShipmentBottomControls">
            <NewShipmentFormButtons
              onSubmit={submitStep}
              onBack={stepsProps.goToPrev}
              isQuoteEnabled={isQuoteEnabled}
              isFirstStep={computeIsFirstStep(stepsProps)}
              isLastStep={computeIsLastStep(stepsProps)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function CancelLink({ className, to = routes.monitor, messageId }) {
  return (
    <InlineLink className={classNames('RedLink', className)} to={to}>
      <FormattedMessage id={messageId} />
    </InlineLink>
  );
}

export function CancelShipmentLink({ className, to = routes.monitor }) {
  return (
    <CancelLink
      className={className}
      to={to}
      messageId="book.newShipment.cancelShipment"
    />
  );
}

export function NewShipmentFormButtons({
  onSubmit,
  onBack,
  isFirstStep,
  isLastStep,
  isQuoteEnabled,
  buttonProps,
  nextLabelId = 'buttons.saveNext',
  primaryButtonIcon,
}) {
  const isPackageEdited = !!useDynamicOriginalValueDependency('packages');
  const isPackageListEmpty =
    getPackageCount(
      useDynamicOriginalValueDependency('packageList.packages')
    ) === 0;
  const isPackageListTbd = useDynamicOriginalValueDependency('packageList.tbd');

  const submitDisabled =
    !isPackageListTbd && (isPackageEdited || isPackageListEmpty);

  const submitLabel = isQuoteEnabled
    ? 'book.newShipment.getQuote'
    : 'book.newShipment.review';

  return (
    <>
      <Button
        onClick={onBack}
        disabled={isFirstStep}
        data-subject="shipment"
        data-action="back"
        {...buttonProps}
      >
        <FAIcon icon="chevron-left" />
        <span>
          <FormattedMessage id="buttons.back" />
        </span>
      </Button>
      <AsyncCallbackButton
        type="primary"
        data-subject="shipment"
        data-action="save"
        callback={onSubmit}
        loadingTextId="buttons.validating"
        disabled={isLastStep && submitDisabled}
        {...buttonProps}
      >
        <span>
          <FormattedMessage id={isLastStep ? submitLabel : nextLabelId} />
        </span>
        {primaryButtonIcon || <FAIcon icon="chevron-right" />}
      </AsyncCallbackButton>
    </>
  );
}

function NewShipmentResponsiveGutterRow({ children, className, ...rest }) {
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm;

  return (
    <Row
      gutter={pxToNumber(
        isSm ? cssVariables.spaceNorm : cssVariables.spaceNorm2
      )}
      className={className}
      {...rest}
    >
      {children}
    </Row>
  );
}

export const NewShipmentBaseRow = ({ className, ...rest }) => (
  <Row
    {...rest}
    className={classNames('no-margin-form-items', className)}
    gutter={[
      pxToNumber(cssVariables.spaceNorm2),
      pxToNumber(cssVariables.spaceNorm),
    ]}
  />
);

const BaseCol = props => (
  <Col {...props} xl={12} lg={12} md={24} sm={24} xs={24} />
);

const joinSchemaName = (schemaNamePrefix, suffix) =>
  schemaNamePrefix ? `${schemaNamePrefix}.${suffix}` : suffix;

export function JobLocationSection({
  title,
  titleId,
  rightContent,
  className,
  titleClassName,
  children,
  bodyProps,
  requiredInTitle,
  ...rest
}) {
  return (
    <div className={classNames('JobLocationCard-Section', className)} {...rest}>
      {(title || titleId || rightContent) && (
        <Row
          className="JobLocationCard-SectionHeader flex-nowrap"
          align="middle"
          justify="space-between"
        >
          <Col
            className={classNames(
              'JobLocationCard-SectionTitle',
              titleClassName
            )}
          >
            {titleId ? <FormattedMessage id={titleId} /> : title}
            {requiredInTitle && ' *'}
          </Col>
          <Col>{rightContent}</Col>
        </Row>
      )}
      <div
        {...bodyProps}
        className={classNames(
          'JobLocationCard-SectionBody',
          bodyProps?.className
        )}
      >
        {children}
      </div>
      <TwoLines className="size-6" />
    </div>
  );
}

function JobLocationFirstCard({ titleId, schemaNamePrefix, children }) {
  const countrySection = (
    <JobLocationSection titleId="book.newShipment.label.countryTerritory">
      <AddressCountryFormItem
        parentName="address"
        parentSchemaName={joinSchemaName(schemaNamePrefix, 'address')}
      />
    </JobLocationSection>
  );
  return (
    <ConfigurableCard
      titleId={titleId}
      bodyComponent="div"
      bodyClassName="JobLocationCardBody no-margin-form-items"
    >
      <NewShipmentBaseRow align="top">
        {children ? (
          <>
            <BaseCol>{countrySection}</BaseCol>
            <BaseCol>{children}</BaseCol>
          </>
        ) : (
          <Col span={24}>{countrySection}</Col>
        )}
      </NewShipmentBaseRow>
    </ConfigurableCard>
  );
}

function AddressLookupSwitch({
  enable,
  headerExtra,
  countrySchemaName,
  children,
  namePrefix,
}) {
  if (!enable) {
    return children;
  }
  const {
    webResults: enableWebResults,
    addressBookResults: enableAddressBookResults,
  } = isObject(enable)
    ? enable
    : { webResults: true, addressBookResults: true };
  return (
    <AddressLookup
      namePrefix={namePrefix}
      renderLookup={field => (
        <JobLocationSection
          className="margin-top-sm"
          titleId="address.label.lookup"
          rightContent={headerExtra}
        >
          <div style={{ position: 'relative' }}>
            {field}
            <div style={{ display: 'none' }}>{children}</div>
          </div>
        </JobLocationSection>
      )}
      countrySchemaName={countrySchemaName}
      enableAddressBookResults={!!enableAddressBookResults}
      enableWebResults={!!enableWebResults}
    >
      {({ onReset }) => (
        <>
          {onReset && (
            <InlineLink
              className="RedLink NewShipment-LinkAboveFirstSection"
              onClick={onReset}
            >
              <FormattedMessage id="buttons.clearForm" />
            </InlineLink>
          )}
          <div>{children}</div>
        </>
      )}
    </AddressLookup>
  );
}

export function AddressLookupCard({
  titleId,
  schemaNamePrefix,
  renderHeaderExtra,
  enableAddressLookup,
  namePrefix,
  showCountry,
}) {
  return (
    <ConfigurableCard
      titleId={titleId}
      bodyComponent="div"
      bodyClassName="JobLocationCardBody no-margin-form-items"
    >
      <AddressLookupSwitch
        enable={enableAddressLookup}
        headerExtra={
          renderHeaderExtra && renderHeaderExtra({ type: 'addressLookup' })
        }
        countrySchemaName={joinSchemaName(schemaNamePrefix, 'address.country')}
        namePrefix={namePrefix}
      >
        <JobLocationSection
          className="margin-top-sm"
          titleId="address.label.address"
          rightContent={
            renderHeaderExtra && renderHeaderExtra({ type: 'addressSection' })
          }
        >
          {showCountry && (
            <AddressCountryFormItem
              parentName={mergeFormNames(namePrefix, 'address')}
              parentSchemaName={joinSchemaName(schemaNamePrefix, 'address')}
              disabled
            />
          )}

          <AddressFormItems
            name={mergeFormNames(namePrefix, 'address')}
            schemaName={joinSchemaName(schemaNamePrefix, 'address')}
          />
          <FormItemInputText
            name={mergeFormNames(namePrefix, 'specialInstructions')}
            schemaName={joinSchemaName(schemaNamePrefix, 'specialInstructions')}
            labelId="book.newShipment.label.specialInstructions"
          />
        </JobLocationSection>
        <JobLocationSection titleId="contact.label.contact">
          <NewShipmentBaseRow>
            <BaseCol>
              <div>
                <FormItemInputText
                  name={mergeFormNames(namePrefix, 'companyName')}
                  schemaName={joinSchemaName(schemaNamePrefix, 'companyName')}
                  labelId="book.newShipment.label.company"
                />
              </div>
            </BaseCol>
            <BaseCol>
              <div>
                <FormItemInputText
                  name={mergeFormNames(namePrefix, ['contact', 'name'])}
                  schemaName={joinSchemaName(schemaNamePrefix, 'contact.name')}
                  labelId="book.newShipment.label.shipmentContact"
                />
              </div>
            </BaseCol>
          </NewShipmentBaseRow>
          <NewShipmentBaseRow>
            <BaseCol>
              <div>
                <FormItemInputText
                  name={mergeFormNames(namePrefix, ['contact', 'email'])}
                  schemaName={joinSchemaName(schemaNamePrefix, 'contact.email')}
                  labelId="book.newShipment.label.email"
                />
              </div>
            </BaseCol>
            <BaseCol>
              <NewShipmentResponsiveGutterRow>
                <Col className="Flex1">
                  <FormItemInputText
                    name={mergeFormNames(namePrefix, ['contact', 'phone'])}
                    schemaName={joinSchemaName(
                      schemaNamePrefix,
                      'contact.phone'
                    )}
                    labelId="book.newShipment.label.phone"
                  />
                </Col>
                <Col>
                  <FormItemInputText
                    className="width-60"
                    name={mergeFormNames(namePrefix, [
                      'contact',
                      'phoneExtension',
                    ])}
                    labelId="book.newShipment.label.phoneExtension"
                  />
                </Col>
              </NewShipmentResponsiveGutterRow>
            </BaseCol>
          </NewShipmentBaseRow>
        </JobLocationSection>
      </AddressLookupSwitch>
    </ConfigurableCard>
  );
}

export function JobLocationCards({
  firstTitleId,
  dateSection,
  secondTitleId,
  renderSecondHeaderExtra,
  schemaNamePrefix,
  enableAddressLookup,
}) {
  return (
    <div className="margins-bottom-norm2">
      <JobLocationFirstCard
        titleId={firstTitleId}
        schemaNamePrefix={schemaNamePrefix}
      >
        {dateSection}
      </JobLocationFirstCard>
      <AddressLookupCard
        titleId={secondTitleId}
        schemaNamePrefix={schemaNamePrefix}
        renderHeaderExtra={renderSecondHeaderExtra}
        enableAddressLookup={enableAddressLookup}
      />
    </div>
  );
}
